// copied from theme.scss
// Required

@import "~bootstrap/scss/functions";
//Import bootstrap overrides variables - theme variables
@import "custom/variables/variables";
@import "custom/variables/variables-dark";

@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
@import "custom/utilities/utilities";
//Import bootstrap scss

@import "~bootstrap/scss/bootstrap.scss";

//Custom css variables for Assan Theme
@import "custom/utilities/root";

//Theme core style import

@import "custom/mixins/transition";
@import "custom/utilities/typography";
@import "custom/utilities/animations";
@import "custom/components/components";

.text-white .form-control {
  color: #16142F !important;
}

body {
  background: $gray-100;
}

.btn-success {
  color: white;
}

.dropdown-menu {
  min-width: 20rem;
}

.dropdown-item, .nav-link {
  text-transform: none;
}

.navbar .navbar-nav .nav-link, .btn {
  text-transform: none;
}

$orange: #FF8811;


.btn.btn-orange {
  @include button-variant($orange, $orange, white)
}

.bg-orange {
  background-color: $orange;
}

.border-orange {
  border-color: $orange;
}

a:hover {
  color: #95c11f;
}

.bg-primary {
  color: white;
}

.bg-primary .text-muted {
  color: var(--bs-light) !important;
}

.bg-primary a {
  color: white;
  text-decoration: underline;
}

.text-bg-white a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: $link-decoration;
}

.form-border a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: $link-decoration;
}

.form-border a.btn-success, .form-border a.btn-primary, .form-border a.btn-orange {
  color: white;
  text-decoration: $link-decoration;
}

.card-hover a {
  color: rgba(var(--#{$prefix}link-color-rgb), var(--#{$prefix}link-opacity, 1));
  text-decoration: $link-decoration;

  &:hover {
    --#{$prefix}link-color-rgb: var(--#{$prefix}link-hover-color-rgb);
    text-decoration: $link-hover-decoration;
  }
}
