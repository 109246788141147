//Jarallax - Parallax Plugin

.jarallax {
    position: relative;
    z-index: 0;
  }
  .jarallax > .jarallax-img {
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }